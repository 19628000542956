var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-auto",attrs:{"id":"create","fluid":""}},[(_vm.user)?_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"ma-auto pa-0",attrs:{"cols":"12","sm":"12","md":"10","lg":"7"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{attrs:{"items":_vm.modules,"color":"custom-accent darken-4","item-text":"module","item-value":"id","label":_vm.$t('selectmodule'),"placeholder":_vm.$t('selectmodule'),"menu-props":{ bottom: true, offsetY: true },"clearable":"","required":"","rules":_vm.selectRules,"return-object":""},on:{"change":_vm.selectModule,"click:clear":_vm.close},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.module))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.module))+" ")]}}],null,false,1069782684),model:{value:(_vm.CurrentModule),callback:function ($$v) {_vm.CurrentModule=$$v},expression:"CurrentModule"}}),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(_vm.SelectedFields)?_c('div',{staticClass:"pa-0 ma-0"},[_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='select'
                          })),function(item){return _c('v-select',{key:item.id,attrs:{"color":"custom-accent darken-4","items":_vm.getLookup(item.lookup),"item-text":"text","item-value":"value","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","rules":_vm.selectRules,"menu-props":{ bottom: true, offsetY: true }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true),model:{value:(_vm.NewItem[item.field]),callback:function ($$v) {_vm.$set(_vm.NewItem, item.field, $$v)},expression:"NewItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='textfield'
                          })),function(item){return _c('v-text-field',{key:item.id,attrs:{"color":"custom-accent darken-4","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","counter":_vm.maxTextfieldLength,"rules":_vm.textfieldRules},model:{value:(_vm.NewItem[item.field]),callback:function ($$v) {_vm.$set(_vm.NewItem, item.field, $$v)},expression:"NewItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='emailfield'
                          })),function(item){return _c('v-text-field',{key:item.id,attrs:{"color":"custom-accent darken-4","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","rules":_vm.emailfieldRules},model:{value:(_vm.NewItem[item.field]),callback:function ($$v) {_vm.$set(_vm.NewItem, item.field, $$v)},expression:"NewItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='phonefield'
                          })),function(item){return _c('v-text-field',{key:item.id,attrs:{"color":"custom-accent darken-4","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","rules":_vm.phonefieldRules},model:{value:(_vm.NewItem[item.field]),callback:function ($$v) {_vm.$set(_vm.NewItem, item.field, $$v)},expression:"NewItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='textarea'
                          })),function(item){return _c('tiptap-vuetify',{key:item.id,staticClass:"my-4",attrs:{"extensions":_vm.extensions,"placeholder":_vm.$t(item.field),"required":"","rules":_vm.textfieldRules,"counter":_vm.maxTextfieldLength},model:{value:(_vm.NewItem[item.field]),callback:function ($$v) {_vm.$set(_vm.NewItem, item.field, $$v)},expression:"NewItem[item.field]"}})}),_c('v-btn',{staticClass:"success mr-2",attrs:{"type":"submit","depressed":"","disabled":!_vm.valid}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('create')))])],1),_c('v-btn',{staticClass:"secondary mr-2",attrs:{"depressed":""},on:{"click":_vm.clear}},[_c('span',[_vm._v(_vm._s(_vm.$t('clear')))])]),_c('v-btn',{staticClass:"warning",attrs:{"depressed":""},on:{"click":_vm.close}},[_c('span',[_vm._v(_vm._s(_vm.$t('close')))])])],2):_vm._e()])],1)],1)],1):_c('v-row',[_vm._v(" user undefined please connect again! ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }