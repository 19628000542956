var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-auto",attrs:{"id":"delete","fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('v-col',{staticClass:"ma-auto pa-0",attrs:{"cols":"12","sm":"12","md":"10","lg":"7"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{attrs:{"items":_vm.modules,"color":"custom-accent darken-4","item-text":"module","item-value":"id","label":_vm.$t('selectmodule'),"placeholder":_vm.$t('selectmodule'),"menu-props":{ bottom: true, offsetY: true },"clearable":"","required":"","rules":_vm.selectRules,"return-object":""},on:{"change":_vm.selectModule,"click:clear":_vm.close},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.module))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.module))+" ")]}}]),model:{value:(_vm.CurrentModule),callback:function ($$v) {_vm.CurrentModule=$$v},expression:"CurrentModule"}}),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(_vm.CurrentModule)?_c('div',{staticClass:"pa-0 ma-0"},[(_vm.getLookup(_vm.CurrentModule.lookup)[0].titleEN)?_c('v-combobox',{key:_vm.getLookup(_vm.CurrentModule.lookup).id,attrs:{"color":"custom-accent darken-4","items":_vm.getLookup(_vm.CurrentModule.lookup),"item-value":"id","item-text":_vm.$i18n.locale=='en'?'titleEN':'titleFR',"label":_vm.$t(_vm.CurrentModule.lookup),"placeholder":_vm.$t(_vm.CurrentModule.lookup),"required":"","rules":_vm.selectRules,"return-object":"","loading":_vm.loading,"clearable":"","small-chips":"","menu-props":{ bottom: true, offsetY: true, maxWidth:500 }},on:{"change":_vm.setDeletedItem,"click:clear":_vm.clear},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.titleEN || item.titleFR)?_c('span',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(1),expression:"1"}],staticClass:"custom-secondary--text pa-0 ma-0"},[_vm._v(_vm._s(_vm.$i18n.locale=='en'?item.titleEN:item.titleFR))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 pa-0"},[(item.titleEN || item.titleFR)?_c('span',{staticClass:"v-html-line-clamp-1 custom-secondary--text",domProps:{"innerHTML":_vm._s(_vm.$i18n.locale=='en'?item.titleEN:item.titleFR)}}):_vm._e(),(item.descriptionEN || item.descriptionFR)?_c('span',{staticClass:"v-html-line-clamp-1 subtitle-1 text--secondary",domProps:{"innerHTML":_vm._s(_vm.$i18n.locale=='en'?item.descriptionEN:item.descriptionFR)}}):_vm._e()])]}}],null,false,421450979),model:{value:(_vm.DeletedItem),callback:function ($$v) {_vm.DeletedItem=$$v},expression:"DeletedItem"}}):_vm._e(),(_vm.getLookup(_vm.CurrentModule.lookup)[0].subject)?_c('v-combobox',{key:_vm.getLookup(_vm.CurrentModule.lookup).id,attrs:{"color":"custom-accent darken-4","items":_vm.getLookup(_vm.CurrentModule.lookup),"item-value":"id","item-text":"subject","label":_vm.$t(_vm.CurrentModule.lookup),"placeholder":_vm.$t(_vm.CurrentModule.lookup),"required":"","rules":_vm.selectRules,"return-object":"","loading":_vm.loading,"clearable":"","small-chips":"","menu-props":{ bottom: true, offsetY: true, maxWidth:500 }},on:{"change":_vm.setDeletedItem,"click:clear":_vm.clear},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.subject)?_c('span',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(1),expression:"1"}],staticClass:"custom-secondary--text pa-0 ma-0"},[_vm._v(_vm._s(item.subject))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 pa-0"},[(item.subject)?_c('span',{staticClass:"v-html-line-clamp-1 custom-secondary--text"},[_vm._v(_vm._s(item.subject))]):_vm._e(),(item.content)?_c('span',{staticClass:"v-html-line-clamp-1 subtitle-1 text--secondary"},[_vm._v(_vm._s(item.content))]):_vm._e()])]}}],null,false,1028310113),model:{value:(_vm.DeletedItem),callback:function ($$v) {_vm.DeletedItem=$$v},expression:"DeletedItem"}}):_vm._e()],1):_vm._e()]),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[(_vm.SelectedFields && _vm.DeletedItem && _vm.CurrentModule)?_c('div',{staticClass:"pa-0 ma-0"},[_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='select'
                          })),function(item){return _c('v-select',{key:item.id,attrs:{"readonly":"","color":"custom-accent darken-4","items":_vm.getLookup(item.lookup),"item-text":"text","item-value":"value","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","rules":_vm.selectRules,"menu-props":{ bottom: true, offsetY: true }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true),model:{value:(_vm.DeletedItem[item.field]),callback:function ($$v) {_vm.$set(_vm.DeletedItem, item.field, $$v)},expression:"DeletedItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='textfield'
                          })),function(item){return _c('v-text-field',{key:item.id,attrs:{"readonly":"","color":"custom-accent darken-4","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","counter":_vm.maxTextfieldLength,"rules":_vm.textfieldRules},model:{value:(_vm.DeletedItem[item.field]),callback:function ($$v) {_vm.$set(_vm.DeletedItem, item.field, $$v)},expression:"DeletedItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='emailfield'
                          })),function(item){return _c('v-text-field',{key:item.id,attrs:{"readonly":"","color":"custom-accent darken-4","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","rules":_vm.emailfieldRules},model:{value:(_vm.DeletedItem[item.field]),callback:function ($$v) {_vm.$set(_vm.DeletedItem, item.field, $$v)},expression:"DeletedItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='phonefield'
                          })),function(item){return _c('v-text-field',{key:item.id,attrs:{"readonly":"","color":"custom-accent darken-4","label":_vm.$t(item.field),"placeholder":_vm.$t(item.field),"required":"","rules":_vm.phonefieldRules},model:{value:(_vm.DeletedItem[item.field]),callback:function ($$v) {_vm.$set(_vm.DeletedItem, item.field, $$v)},expression:"DeletedItem[item.field]"}})}),_vm._l((_vm.SelectedFields.filter(function (field){
                              return field.type=='textarea'
                          })),function(item){return _c('tiptap-vuetify',{key:item.id,staticClass:"my-4",attrs:{"extensions":_vm.extensions,"disabled":"","placeholder":_vm.$t(item.field),"rules":_vm.textfieldRules},model:{value:(_vm.DeletedItem[item.field]),callback:function ($$v) {_vm.$set(_vm.DeletedItem, item.field, $$v)},expression:"DeletedItem[item.field]"}})}),_c('v-btn',{staticClass:"success mr-2",attrs:{"type":"submit","depressed":"","disabled":!_vm.valid}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])],1),_c('v-btn',{staticClass:"secondary mr-2",attrs:{"depressed":""},on:{"click":_vm.clear}},[_c('span',[_vm._v(_vm._s(_vm.$t('clear')))])]),_c('v-btn',{staticClass:"warning",attrs:{"depressed":""},on:{"click":_vm.close}},[_c('span',[_vm._v(_vm._s(_vm.$t('close')))])])],2):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }