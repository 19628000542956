<template> 
  <v-container id="create" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0" v-if="user">
        <v-col cols="12" sm="12" md="10" lg="7" class="ma-auto pa-0">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
                <v-select
                :items="modules"
                v-model="CurrentModule"
                color="custom-accent darken-4"
                item-text="module"
                item-value="id"
                :label="$t('selectmodule')"
                :placeholder="$t('selectmodule')"
                :menu-props="{ bottom: true, offsetY: true }"
                @change="selectModule"
                @click:clear="close"
                clearable
                required
                :rules="selectRules"
                return-object>
                    <template v-slot:selection="{ item }">
                        {{$t(item.module)}}
                    </template>
                    <template v-slot:item="{ item }">
                        {{$t(item.module)}}
                    </template>
                </v-select>
                <transition name="fade" mode="in-out">
                    <div class="pa-0 ma-0" v-if="SelectedFields">
                        <v-select
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='select'
                            })"
                            v-model="NewItem[item.field]"
                            color="custom-accent darken-4"
                            :items="getLookup(item.lookup)"
                            item-text="text"
                            item-value="value"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :rules="selectRules"
                            :menu-props="{ bottom: true, offsetY: true }">
                                <template v-slot:selection="{ item }">
                                    {{item.text}}
                                </template>
                                <template v-slot:item="{ item }">
                                    {{item.text}}
                                </template>
                        </v-select>
                        <v-text-field
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='textfield'
                            })"
                            v-model="NewItem[item.field]"
                            color="custom-accent darken-4"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :counter="maxTextfieldLength"
                            :rules="textfieldRules"
                        ></v-text-field>
                        <v-text-field
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='emailfield'
                            })"
                            v-model="NewItem[item.field]"
                            color="custom-accent darken-4"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :rules="emailfieldRules"
                        ></v-text-field>
                        <v-text-field
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='phonefield'
                            })"
                            v-model="NewItem[item.field]"
                            color="custom-accent darken-4"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :rules="phonefieldRules"
                        ></v-text-field>
                        <tiptap-vuetify
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='textarea'
                            })"
                            class="my-4"
                            v-model="NewItem[item.field]"
                            :extensions="extensions"
                            :key="item.id"
                            :placeholder="$t(item.field)"
                            required
                            :rules="textfieldRules"
                            :counter="maxTextfieldLength"
                        ></tiptap-vuetify>
                        <v-btn type="submit" depressed :disabled="!valid" class="success mr-2">
                            <v-progress-circular v-if="loading"
                                indeterminate
                            ></v-progress-circular>
                            <span v-else>{{$t('create')}}</span>
                        </v-btn>
                        <v-btn @click="clear" class="secondary mr-2" depressed>
                            <span>{{$t('clear')}}</span>
                        </v-btn>
                        <v-btn @click="close" class="warning" depressed>
                            <span>{{$t('close')}}</span>
                        </v-btn>
                    </div>
                </transition>
            </v-form>
        </v-col>
    </v-row>
    <v-row v-else>
        user undefined please connect again!
    </v-row>
  </v-container>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import { TiptapVuetify, History, Paragraph, Bold, Italic, Underline, Link, ListItem, BulletList, OrderedList, Heading, Strike, Code, HorizontalRule, HardBreak } from 'tiptap-vuetify';

export default({
    name: 'Create',
    props: ['modules','blog_categories','contact_types','mapped_products','loading'],
    data() {
        return {
            CurrentModule:null,
            SelectedFields:null,
            NewItem:{},
            valid: false,
            extensions: [
                History,
                Paragraph,
                Bold,
                Italic,
                Underline,
                Link,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                Strike,
                Code,
                HorizontalRule,
                HardBreak
            ],
        }
    },
    components: { TiptapVuetify },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        maxTextareaLength () {
        return 3500;
        },
        selectRules(){
            return [
                v => !!v || this.$t('fieldError'),
            ]
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        emailfieldRules(){ 
            const phoneRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => phoneRegex.test(v) || this.$t('emailValidError'),
            ]
        },
        phonefieldRules(){ 
            const phoneRegex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => phoneRegex.test(v) || this.$t('phoneValidError'),
            ]
        },
        textareaRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextareaLength || this.$t('fieldValidError1')+' '+this.maxTextareaLength+' '+this.$t('fieldValidError2'),
            ]
        },

        ...mapGetters({
            user:'getUser'
        }),
    },
    watch:{},
    methods: {
        selectModule(item){
            if(item)
            this.SelectedFields=JSON.parse(item.fields);
        },
        getLookup(item){
            return this[item]
        },
        submit(){
            if(this.$refs.form.validate()){
                switch (this.CurrentModule.module) {
                    case 'newsevents':
                        this.NewItem['author']=this.user.id;
                        this.addBlog(this.NewItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemadded'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'jobseeker':
                        this.NewItem['author']=this.user.id;
                        this.addOpportunity(this.NewItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemadded'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'contact':
                        this.addContact(this.NewItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemadded'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'products':
                        this.addProduct(this.NewItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemadded'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'itservices':
                        this.addService(this.NewItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemadded'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'productservices':
                        this.addProductService(this.NewItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemadded'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    default:
                        break;
                }
            }
        },
        clear(){
            this.NewItem={};
            this.$refs.form.resetValidation();
        },
        close(){
            this.SelectedFields=null;
            this.CurrentModule=null;
            this.clear();
            this.$refs.form.reset();
        },

        ...mapActions("blog", ["addBlog"]),
        ...mapActions("opportunity", ["addOpportunity"]),
        ...mapActions("contact", ["addContact"]),
        ...mapActions("product", ["addProduct"]),
        ...mapActions("product_service", ["addProductService"]),
        ...mapActions("service", ["addService"]),
    }
})
</script>
<style scoped>
</style>
