<template>
  <v-container id="employer" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0 mt-12">
          <v-col class="ma-auto">
             <v-tabs
              v-model="tab"
              background-color="transparent"
              color="custom-accent"
              center-active
              show-arrows
              icons-and-text
              centered
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="item in tabs"
                :key="item.tab"
              >
                {{ $t(item.tab) }}
                <v-icon>{{item.icon}}</v-icon>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="12" md="10" lg="10" class="ma-auto">
            <v-tabs-items v-model="tab" class="transparent">
                <v-tab-item v-for="item in tabs" :key="item.tab">
                    <v-row class="ma-0 pa-0" style="min-height:400px;">
                        <v-col class="ma-auto pa-0">
                            <v-card v-if="item.tab=='create'" class="elevation-0 transparent ma-2"><admin-create :modules="modules" :blog_categories="blog_categories" :contact_types="contact_types" :mapped_products="mapped_products" :loading="loading"></admin-create></v-card>
                            <v-card v-if="item.tab=='read'" class="elevation-0 transparent ma-2"><admin-read :modules="modules" :blog_categories="blog_categories" :contact_types="contact_types" :loading="loading" :blogs="blogs" :opportunities="opportunities" :contacts="contacts" :products="products" :mapped_products="mapped_products" :productservices="productservices" :services="services"></admin-read></v-card>
                            <v-card v-if="item.tab=='update'" class="elevation-0 transparent ma-2"><admin-update :modules="modules" :blog_categories="blog_categories" :contact_types="contact_types" :loading="loading" :blogs="blogs" :opportunities="opportunities" :contacts="contacts" :products="products" :mapped_products="mapped_products" :productservices="productservices" :services="services"></admin-update></v-card>
                            <v-card v-if="item.tab=='delete'" class="elevation-0 transparent ma-2"><admin-delete :modules="modules" :blog_categories="blog_categories" :contact_types="contact_types" :loading="loading" :blogs="blogs" :opportunities="opportunities" :contacts="contacts" :products="products" :mapped_products="mapped_products" :productservices="productservices" :services="services"></admin-delete></v-card>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import admincreate from '../../components/Admin/AdminCreate.vue';
import adminread from '../../components/Admin/AdminRead.vue';
import adminupdate from '../../components/Admin/AdminUpdate.vue';
import admindelete from '../../components/Admin/AdminDelete.vue';
import { mapState,mapActions,mapGetters } from "vuex";
export default {
  name: "Employer",

  data() {
    return {
        tab: null,
        tabs: [
            { tab:'create',icon:'mdi-plus-box' },
            { tab:'read',icon:'mdi-view-list' },
            { tab:'update',icon:'mdi-clipboard-edit' },
            { tab:'delete',icon:'mdi-delete' },
        ],
        mapped_products:[],
        modules:[{id:1,module:'newsevents',lookup:'blogs',fields:'[{"id":1,"field":"titleEN","type":"textfield","lookup":null},{"id":2,"field":"titleFR","type":"textfield","lookup":null},{"id":3,"field":"descriptionEN","type":"textarea","lookup":null},{"id":4,"field":"descriptionFR","type":"textarea","lookup":null},{"id":5,"field":"category","type":"select","lookup":"blog_categories"}]'},
        {id:2,module:'jobseeker',lookup:'opportunities',fields:'[{"id":1,"field":"titleEN","type":"textfield","lookup":null},{"id":2,"field":"titleFR","type":"textfield","lookup":null},{"id":3,"field":"descriptionEN","type":"textarea","lookup":null},{"id":4,"field":"descriptionFR","type":"textarea","lookup":null}]'},
        {id:3,module:'products',lookup:'products',fields:'[{"id":1,"field":"titleEN","type":"textfield","lookup":null},{"id":2,"field":"titleFR","type":"textfield","lookup":null},{"id":3,"field":"subtitleEN","type":"textfield","lookup":null},{"id":4,"field":"subtitleFR","type":"textfield","lookup":null},{"id":5,"field":"descriptionEN","type":"textarea","lookup":null},{"id":6,"field":"descriptionFR","type":"textarea","lookup":null}]'},
        {id:4,module:'productservices',lookup:'productservices',fields:'[{"id":1,"field":"titleEN","type":"textfield","lookup":null},{"id":2,"field":"titleFR","type":"textfield","lookup":null},{"id":3,"field":"icon","type":"textfield","lookup":null},{"id":4,"field":"descriptionEN","type":"textarea","lookup":null},{"id":5,"field":"descriptionFR","type":"textarea","lookup":null},{"id":6,"field":"product","type":"select","lookup":"mapped_products"}]'},
        {id:5,module:'itservices',lookup:'services',fields:'[{"id":1,"field":"titleEN","type":"textfield","lookup":null},{"id":2,"field":"titleFR","type":"textfield","lookup":null},{"id":3,"field":"icon","type":"textfield","lookup":null},{"id":4,"field":"descriptionEN","type":"textarea","lookup":null},{"id":5,"field":"descriptionFR","type":"textarea","lookup":null}]'},
        {id:6,module:'contact',lookup:'contacts',fields:'[{"id":1,"field":"name","type":"textfield","lookup":null},{"id":2,"field":"email","type":"emailfield","lookup":null},{"id":3,"field":"phone","type":"phonefield","lookup":null},{"id":4,"field":"subject","type":"textfield","lookup":null},{"id":5,"field":"content","type":"textarea","lookup":null},{"id":6,"field":"type","type":"select","lookup":"contact_types"}]'}],
    };
  },
  components: {
    'admin-create':admincreate,
    'admin-read':adminread,
    'admin-update':adminupdate,
    'admin-delete':admindelete,
  },
  async created(){
    await this.retrieveOpportunities();
    await this.retrieveBlogs();
    await this.retrieveContacts();
    await this.retrieveProducts().then(()=>{
      this.mapped_products = this.products.map((item)=>{
        return {id:item.id,value:item.id,text:this.$i18n.locale=='en'?item.titleEN:item.titleFR};
      })
    });
    await this.retrieveProductServices();
    await this.retrieveServices();
  },
  computed: {
        blog_categories: function () {
          return [{id:1,value:'news',text:this.$t('news')},{id:2,value:'events',text:this.$t('events')},{id:3,value:'archived',text:this.$t('archived')}];
        },
        contact_types: function () {
          return [{id:1,value:'admin',text:this.$t('admin')},{id:2,value:'about',text:this.$t('about')},{id:3,value:'contact',text:this.$t('contact')},{id:4,value:'home',text:this.$t('home')},{id:5,value:'jobseeker',text:this.$t('jobseeker')},{id:6,value:'services',text:this.$t('services')}];
        },
        ...mapState("blog", {
          blog_loading: "loading"
        }),
        ...mapState("opportunity", {
          opportunity_loading: "loading"
        }),
        ...mapState("contact", {
          contact_loading: "loading"
        }),
        ...mapState("product", {
          product_loading: "loading"
        }),
        ...mapState("product_service", {
          product_service_loading: "loading"
        }),
        ...mapState("service", {
          service_loading: "loading"
        }),
        ...mapGetters('blog', {
          blogs:'getBlogs'
        }),
        ...mapGetters('opportunity', {
          opportunities:'getOpportunities'
        }),
        ...mapGetters('contact', {
          contacts:'getContacts'
        }),
        ...mapGetters('product_service', {
          productservices:'getProductServices'
        }),
        ...mapGetters('product', {
          products:'getProducts'
        }),
        ...mapGetters('service', {
          services:'getServices'
        }),
        loading(){
            return this.blog_loading || this.opportunity_loading || this.contact_loading || this.product_loading || this.service_loading || this.product_service_loading;
        },
  },
  watch: {
    products(val){
      this.mapped_products = val.map((item)=>{
        return {id:item.id,value:item.id,text:this.$i18n.locale=='en'?item.titleEN:item.titleFR};
      })
    },
  },
  methods: {
    
    ...mapActions("blog", ["retrieveBlogs"]),
    ...mapActions("opportunity", ["retrieveOpportunities"]),
    ...mapActions("contact", ["retrieveContacts"]),
    ...mapActions("product", ["retrieveProducts"]),
    ...mapActions("product_service", ["retrieveProductServices"]),
    ...mapActions("service", ["retrieveServices"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/admin.css");
</style>