<template> 
  <v-container id="update" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="12" md="10" lg="7" class="ma-auto pa-0">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
                <v-select
                :items="modules"
                v-model="CurrentModule"
                color="custom-accent darken-4"
                item-text="module"
                item-value="id"
                :label="$t('selectmodule')"
                :placeholder="$t('selectmodule')"
                :menu-props="{ bottom: true, offsetY: true }"
                @change="selectModule"
                @click:clear="close"
                clearable
                required
                :rules="selectRules"
                return-object>
                    <template v-slot:selection="{ item }">
                        {{$t(item.module)}}
                    </template>
                    <template v-slot:item="{ item }">
                        {{$t(item.module)}}
                    </template>
                </v-select>
                <transition name="fade" mode="in-out">
                    <div class="pa-0 ma-0" v-if="CurrentModule">
                      <v-combobox v-if="getLookup(CurrentModule.lookup)[0].titleEN"
                            v-model="UpdatedItem"
                            color="custom-accent darken-4"
                            :items="getLookup(CurrentModule.lookup)"
                            item-value="id"
                            :item-text="$i18n.locale=='en'?'titleEN':'titleFR'"
                            :key="getLookup(CurrentModule.lookup).id"
                            :label="$t(CurrentModule.lookup)"
                            :placeholder="$t(CurrentModule.lookup)"
                            required
                            @change="setUpdatedItem"
                            :rules="selectRules"
                            return-object
                            :loading="loading"
                            @click:clear="clear"
                            clearable
                            small-chips
                            :menu-props="{ bottom: true, offsetY: true, maxWidth:500 }">
                              <template v-slot:selection="{ item }">
                                  <span class="custom-secondary--text pa-0 ma-0" v-line-clamp="1" v-if="item.titleEN || item.titleFR">{{$i18n.locale=='en'?item.titleEN:item.titleFR}}</span>
                              </template>
                              <template v-slot:item="{ item }">
                                <div class="my-2 pa-0">
                                  <span class="v-html-line-clamp-1 custom-secondary--text" v-if="item.titleEN || item.titleFR" v-html="$i18n.locale=='en'?item.titleEN:item.titleFR"></span>
                                  <span class="v-html-line-clamp-1 subtitle-1 text--secondary" v-if="item.descriptionEN || item.descriptionFR" v-html="$i18n.locale=='en'?item.descriptionEN:item.descriptionFR"></span>
                                </div>
                              </template>
                        </v-combobox>
                      <v-combobox v-if="getLookup(CurrentModule.lookup)[0].subject"
                            v-model="UpdatedItem"
                            color="custom-accent darken-4"
                            :items="getLookup(CurrentModule.lookup)"
                            item-value="id"
                            item-text="subject"
                            :key="getLookup(CurrentModule.lookup).id"
                            :label="$t(CurrentModule.lookup)"
                            :placeholder="$t(CurrentModule.lookup)"
                            required
                            @change="setUpdatedItem"
                            :rules="selectRules"
                            return-object
                            :loading="loading"
                            @click:clear="clear"
                            clearable
                            small-chips
                            :menu-props="{ bottom: true, offsetY: true, maxWidth:500 }">
                              <template v-slot:selection="{ item }">
                                  <span class="custom-secondary--text pa-0 ma-0" v-line-clamp="1" v-if="item.subject">{{item.subject}}</span>
                              </template>
                              <template v-slot:item="{ item }">
                                <div class="my-2 pa-0">
                                  <span class="v-html-line-clamp-1 custom-secondary--text" v-if="item.subject">{{item.subject}}</span>
                                  <span class="v-html-line-clamp-1 subtitle-1 text--secondary" v-if="item.content">{{item.content}}</span>
                                </div>
                              </template>
                        </v-combobox>
                    </div>
                </transition>
                <transition name="fade" mode="in-out">
                    <div class="pa-0 ma-0" v-if="SelectedFields && UpdatedItem && CurrentModule">
                        <v-select
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='select'
                            })"
                            v-model="UpdatedItem[item.field]"
                            color="custom-accent darken-4"
                            :items="getLookup(item.lookup)"
                            item-text="text"
                            item-value="value"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :rules="selectRules"
                            :menu-props="{ bottom: true, offsetY: true }">
                                <template v-slot:selection="{ item }">
                                    {{item.text}}
                                </template>
                                <template v-slot:item="{ item }">
                                    {{item.text}}
                                </template>
                        </v-select>
                        <v-text-field
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='textfield'
                            })"
                            v-model="UpdatedItem[item.field]"
                            color="custom-accent darken-4"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :counter="maxTextfieldLength"
                            :rules="textfieldRules"
                        ></v-text-field>
                        <v-text-field
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='emailfield'
                            })"
                            v-model="UpdatedItem[item.field]"
                            color="custom-accent darken-4"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :rules="emailfieldRules"
                        ></v-text-field>
                        <v-text-field
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='phonefield'
                            })"
                            v-model="UpdatedItem[item.field]"
                            color="custom-accent darken-4"
                            :key="item.id"
                            :label="$t(item.field)"
                            :placeholder="$t(item.field)"
                            required
                            :rules="phonefieldRules"
                        ></v-text-field>
                        <tiptap-vuetify
                            v-for="item in SelectedFields.filter((field)=>{
                                return field.type=='textarea'
                            })"
                            class="my-4"
                            v-model="UpdatedItem[item.field]"
                            :extensions="extensions"
                            :key="item.id"
                            :placeholder="$t(item.field)"
                            :rules="textareaRules"
                        ></tiptap-vuetify>
                        <v-btn type="submit" depressed :disabled="!valid" class="success mr-2">
                            <v-progress-circular v-if="loading"
                                indeterminate
                            ></v-progress-circular>
                            <span v-else>{{$t('update')}}</span>
                        </v-btn>
                        <v-btn @click="clear" class="secondary mr-2" depressed>
                            <span>{{$t('clear')}}</span>
                        </v-btn>
                        <v-btn @click="close" class="warning" depressed>
                            <span>{{$t('close')}}</span>
                        </v-btn>
                    </div>
                </transition>
            </v-form>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TiptapVuetify, History, Paragraph, Bold, Italic, Underline, Link, ListItem, BulletList, OrderedList, Heading, Strike, Code, HorizontalRule, HardBreak } from 'tiptap-vuetify';

import { mapActions } from "vuex";
export default({
    name: 'Update',
    props: ['modules','blog_categories','contact_types','loading','blogs','opportunities','contacts','products','mapped_products','productservices','services'],
    data() {
        return {
            CurrentModule:null,
            SelectedFields:null,
            UpdatedItem:{},
            valid: false,
            extensions: [
                History,
                Paragraph,
                Bold,
                Italic,
                Underline,
                Link,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                Strike,
                Code,
                HorizontalRule,
                HardBreak
            ],
        }
    },
    components: { TiptapVuetify },
    async created(){
    },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        maxTextareaLength () {
        return 3500;
        },
        selectRules(){
            return [
                v => !!v || this.$t('fieldError'),
            ]
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },
        emailfieldRules(){ 
            const phoneRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => phoneRegex.test(v) || this.$t('emailValidError'),
            ]
        },
        phonefieldRules(){ 
            const phoneRegex = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            return [
                v => !!v || this.$t('fieldError'),
                v => phoneRegex.test(v) || this.$t('phoneValidError'),
            ]
        },
        textareaRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextareaLength || this.$t('fieldValidError1')+' '+this.maxTextareaLength+' '+this.$t('fieldValidError2'),
            ]
        },
    },
    watch:{},
    methods: {
        selectModule(item){
          if(item){
            this.clear();
            this.SelectedFields=JSON.parse(item.fields);
          }
        },
        getLookup(item){
          return this[item]
        },
        setUpdatedItem(item){
          if(item){
            this.clear();
            Object.assign(this.UpdatedItem,this.$getObjectById(this[this.CurrentModule.lookup],item.id))
          }
        },
        submit(){
            if(this.$refs.form.validate()){
                switch (this.CurrentModule.module) {
                    case 'newsevents':
                        this.updateBlog(this.UpdatedItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemupdated'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'jobseeker':
                        this.updateOpportunity(this.UpdatedItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemupdated'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'contact':
                        this.updateContact(this.UpdatedItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemupdated'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'products':
                        this.updateProduct(this.UpdatedItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemupdated'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'itservices':
                        this.updateService(this.UpdatedItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemupdated'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    case 'productservices':
                        this.updateProductService(this.UpdatedItem).then(() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-check-circle', state:true, content:this.$t('itemupdated'), type:'success'});
                                this.clear();
                            },() => {
                                this.$root.$emit('callSnackbar', { icon:'mdi-alert', state:true, content:this.$t('error'), type:'warning'});
                            }
                        );
                        break;
                
                    default:
                        break;
                }
            }
        },
        clear(){
            this.UpdatedItem={};
            this.$refs.form.resetValidation();
        },
        close(){
            this.clear();
            this.SelectedFields=null;
            this.CurrentModule=null;
        },

        ...mapActions("blog", ["updateBlog"]),
        ...mapActions("opportunity", ["updateOpportunity"]),
        ...mapActions("contact", ["updateContact"]),
        ...mapActions("product", ["updateProduct"]),
        ...mapActions("product_service", ["updateProductService"]),
        ...mapActions("service", ["updateService"]),
    }
})
</script>
<style scoped>
</style>
